<main>
  <div *ngIf="baseUrl !== 'https://www.dhl-ddm.com'">
    <img src="assets/img/dhl-logo.svg" alt="DHL logo">
    <form [formGroup]="loginForm" (submit)="login($event)">
      <div>
        <mat-form-field>
          <mat-label>User</mat-label>
          <mat-icon matPrefix color="primary">person</mat-icon>
          <input matInput type="text" name="user" formControlName="user" required>
          <mat-error *ngIf="loginForm.get('user').invalid">{{getUserErrorMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <mat-icon matPrefix color="primary">lock</mat-icon>
          <input name="password" matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" required>
          <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
            <mat-icon color="primary">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.get('password').invalid">{{getPasswordErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <button type="submit" mat-flat-button color="accent">Login</button>
    </form>
  </div>
</main>