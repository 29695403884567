import { AuthService } from 'src/app/services/auth.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { IAgreement, IPerdiem, IRulCou, IRulCtnType, IRulPort, IRulTrd } from 'src/app/models/agreement.model';
import { ICustomerGroup } from 'src/app/models/customer-group.model';
import { ICustomer } from 'src/app/models/customer.model';
import { ICarrier, IContainerType, ICountry, IPort, IRegion } from 'src/app/models/parameter.model';
import { AgreementService } from 'src/app/services/agreement.service';
import { CustomerGroupService } from 'src/app/services/customer-group.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { AgreementDialogComponent } from './agreement-dialogs/agreement-dialog/agreement-dialog.component';
import { CustomerGroupsDialogComponent, ICustomerGroupDialog } from '../customer-groups/customer-groups-dialogs/customer-groups-dialog/customer-groups-dialog.component';
import { IComment } from 'src/app/models/comment.model';
import { CommentService } from 'src/app/services/comment.service';
import { AwsService } from 'src/app/services/aws.service';
import { AttachmentService } from 'src/app/services/attachment.service';
import { IAttachment } from 'src/app/models/attachment.model';
import { ITimeline } from 'src/app/models/timeline.model';
import { TimelineService } from 'src/app/services/timeline.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dhl-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent implements OnInit {
  isAgreementInformationOpen: boolean = true;
  isCustomerInformationOpen: boolean = true;
  isAgreementParametersOpen: boolean = true;
  agreementForm: UntypedFormGroup;

  customerGroups: Array<ICustomerGroup>;
  filteredCustomerGroups: Subject<Array<ICustomerGroup>> = new Subject<Array<ICustomerGroup>>();

  customers: Array<ICustomer>;
  shippers: Array<ICustomer>;
  notifies: Array<ICustomer>;

  carriers: Array<ICarrier>;
  filteredCarriers: Subject<Array<ICarrier>> = new Subject<Array<ICarrier>>();

  containerTypes: Array<IContainerType>;
  filteredContainerTypes: Subject<Array<IContainerType>> = new Subject<Array<IContainerType>>();

  countries: Array<ICountry>;
  filteredOriginCountries: Subject<Array<ICountry>> = new Subject<Array<ICountry>>();
  filteredDestinationCountries: Subject<Array<ICountry>> = new Subject<Array<ICountry>>();

  ports: Array<IPort>;
  filteredOriginPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();
  filteredDestinationPorts: Subject<Array<IPort>> = new Subject<Array<IPort>>();

  regions: Array<IRegion>;
  filteredRegions: Subject<Array<IRegion>> = new Subject<Array<IRegion>>();

  agreementType: string;
  agreementAction: string;

  agreementTableDataSource = new MatTableDataSource<IAgreement>();
  filteredAgreementTableDataSource = new MatTableDataSource<IAgreement>();
  agreementTableDisplayedColumns: Array<string> = ['select', 'DIM_SK_CUS_RUL', 'DIM_DESCRIPTION', 'DIM_TYPE', 'DIM_SERVICE',  'DIM_DT_STR_EFF', 'DIM_DT_FIN_EFF', 'DIM_CAR', 'CTN_TYPE_LIST', 'REG_LIST', 'COU_LIST', 'PORT_LIST'];

  @ViewChild(MatTable) agreementTable: MatTable<IAgreement>;
  @ViewChild('agreementSort') agreementSort: MatSort;
  @ViewChild('agreementPaginator') agreementPaginator: MatPaginator;
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();

  agreementId: number;
  agreement: IAgreement;

  cloned: boolean = false;

  addOnBlur: boolean = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  requestedByList: Array<string> = [];
  approvedByList: Array<string> = [];

  defaultRules: Array<IAgreement> = [];
  defaultRulePerdiem: Array<IPerdiem> = [];

  purchaseVisible = false;

  isSidebarOpen = false;
  comments: Array<IComment> = [];
  attachments: Array<IAttachment> = [];
  timeline: Array<ITimeline> = [];

  constructor(
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private customerGroupService: CustomerGroupService,
    private customerService: CustomerService,
    private parameterService: ParameterService,
    private agreementService: AgreementService,
    private awsService: AwsService,
    private commentService: CommentService,
    private attachmentService: AttachmentService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private toast: HotToastService
  ) {
    this.titleService.setTitle('DHL | Agreement');

    this.customerGroupService.getCustomerGroup().subscribe((customerGroups) => {
      this.customerGroups = customerGroups.sort(function(a, b) {
        return a.CUS_GRP_NAME.localeCompare(b.CUS_GRP_NAME);
      });
      this.filteredCustomerGroups.next(customerGroups);
    });

    this.getCustomers(null, 'customer');

    this.parameterService.getCarrier().subscribe((carriers) => {
      this.carriers = carriers;
      this.filteredCarriers.next(carriers);
    });

    this.parameterService.getContainerTypeParameter().subscribe((containerTypes) => {
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });

    this.parameterService.getContainerTypeParameter().subscribe((containerTypes) => {
      this.containerTypes = containerTypes;
      this.filteredContainerTypes.next(containerTypes);
    });

    this.parameterService.getCountry().subscribe((countries) => {
      this.countries = countries;
      this.filteredOriginCountries.next(countries);
      this.filteredDestinationCountries.next(countries);
    });

    this.parameterService.getPort().subscribe((ports) => {
      this.ports = ports;
      this.filteredOriginPorts.next(ports);
      this.filteredDestinationPorts.next(ports);
    });

    this.parameterService.getRegion().subscribe((regions) => {
      this.regions = regions;
      this.filteredRegions.next(regions);
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.agreementType = params.type;
      this.agreementAction = params.action;
      this.agreementId = params.id;
    });

    let group: number | null = null;
    let customer: number | null = null;
    let shipper: number | null = null;
    let notify: number | null = null;
    let carrier: number | null = null;
    let containerTypeList = [];
    let originTradeList = [];
    let originCountryList = [];
    let polList = [];
    let destinationTradeList = [];
    let destinationCountryList = [];
    let podList = [];

    this.agreement = this.route.snapshot.data['agreement'];

    if (this.agreementAction === 'clone') {
      this.cloned = true;
      this.isAgreementInformationOpen = false;
      this.isCustomerInformationOpen = false;
      this.agreement.DIM_SK_CUS_RUL = null;
      this.agreement.DIM_CAR = null;
      this.agreement.DIM_NAC_FAK = null;
      this.agreement.DIM_CONTRACT = null;
      this.agreement.CTN_TYPE_LIST = [];
      this.agreement.DIM_VL_FRT_AMT = null;
      this.agreement.DIM_RUL_PT_ID = null;
      this.agreement.DIM_PERDIEM_TYPE = null;
    }


    if (this.agreement) {
      group = Number(this.agreement.DIM_CUS_GRP);
      customer = Number(this.agreement.DIM_CUS_CODE);
      shipper = Number(this.agreement.DIM_SHIPPER);
      notify = Number(this.agreement.DIM_NOTIFY);
      carrier = this.agreement.DIM_CAR ? Number(this.agreement.DIM_CAR) : 0;
      containerTypeList = this.agreement.CTN_TYPE_LIST.map((el) => Number(el.DIM_SK_CTN_TYP));
      originTradeList = this.agreement.REG_LIST.filter((el) => el.DIM_RUL_TRD_TYPE === 'O').map(el => Number(el.REG_ID));
      originCountryList = this.agreement.COU_LIST.filter((el) => el.DIM_RUL_COU_TYPE === 'O').map(el => Number(el.COU_ID));
      polList = this.agreement.PORT_LIST.filter((el) => el.DIM_RUL_PORT_TYPE === 'O').map(el => Number(el.DIM_SK_PORT));
      destinationTradeList = this.agreement.REG_LIST.filter((el) => el.DIM_RUL_TRD_TYPE === 'D').map(el => Number(el.REG_ID));
      destinationCountryList = this.agreement.COU_LIST.filter((el) => el.DIM_RUL_COU_TYPE === 'D').map(el => Number(el.COU_ID));
      podList = this.agreement.PORT_LIST.filter((el) => el.DIM_RUL_PORT_TYPE === 'D').map(el => Number(el.DIM_SK_PORT));

      this.getCustomers(customer, 'customer');
      this.getCustomers(shipper, 'shipper');
      this.getCustomers(notify, 'notify');

      this.getComments();
      this.getAttachments();
      this.getTimeline();
    }

    this.requestedByList = this.agreement?.DIM_REQUESTED.split(', ') || [];
    this.approvedByList = this.agreement?.DIM_APPROVED.split(', ') || [];
    const status = isNaN(Number(this.agreement?.DIM_STATUS)) ? 1 : Number(this.agreement?.DIM_STATUS);

    this.agreementForm = this.formBuilder.group({
      hblmbl: new UntypedFormControl(this.agreement?.DIM_DOSIERPF_BHFFTX || '', [Validators.required]),
      description: new UntypedFormControl(this.agreement?.DIM_DESCRIPTION.toLowerCase() || 'bid', [Validators.required]),
      type: new UntypedFormControl(this.agreementType.toUpperCase() || '', [Validators.required]),
      service: new UntypedFormControl(this.agreement?.DIM_SERVICE.toLowerCase() || '', [Validators.required]),
      startDate: new UntypedFormControl(new Date(this.agreement?.DIM_DT_STR_EFF) || '', [Validators.required]),
      validityDate: new UntypedFormControl(new Date(this.agreement?.DIM_DT_FIN_EFF) || '', [Validators.required]),
      bidDate: new UntypedFormControl(this.agreement?.DIM_DT_BID_EXP ? new Date(this.agreement?.DIM_DT_BID_EXP) : ''),
      status: new UntypedFormControl(status, [Validators.required]),
      dhlContract: new UntypedFormControl(this.agreement?.DIM_DGF || '', [Validators.required]),
      requestedBy: new UntypedFormControl('', [Validators.required, Validators.email]),
      approvedBy: new UntypedFormControl('', [Validators.required, Validators.email]),
      group: new UntypedFormControl(group, [Validators.required]),
      groupFilter: new UntypedFormControl(''),
      customer: new UntypedFormControl(customer, [Validators.required]),
      customerFilter: new UntypedFormControl(''),
      shipper: new UntypedFormControl(shipper, [Validators.required]),
      shipperFilter: new UntypedFormControl(''),
      notify: new UntypedFormControl(notify, [Validators.required]),
      notifyFilter: new UntypedFormControl(''),
      carrier: new UntypedFormControl(carrier, [Validators.required]),
      carrierFilter: new UntypedFormControl(''),
      faknac: new UntypedFormControl(this.agreement?.DIM_NAC_FAK || '', [Validators.required]),
      contract: new UntypedFormControl(this.agreement?.DIM_CONTRACT || '', [Validators.required]),
      containerType: new UntypedFormControl(containerTypeList, [Validators.required]),
      containerTypeFilter: new UntypedFormControl(''),
      startReference: new UntypedFormControl(this.agreement?.DIM_STR_REF || '', [Validators.required]),
      endReference: new UntypedFormControl(this.agreement?.DIM_END_REF || '', [Validators.required]),
      originTrade: new UntypedFormControl(originTradeList, [Validators.required]),
      originTradeFilter: new UntypedFormControl(''),
      originCountry: new UntypedFormControl(originCountryList, [Validators.required]),
      originCountryFilter: new UntypedFormControl(''),
      pol: new UntypedFormControl(polList, [Validators.required]),
      polFilter: new UntypedFormControl(''),
      destinationTrade: new UntypedFormControl(destinationTradeList, [Validators.required]),
      destinationTradeFilter: new UntypedFormControl(''),
      destinationCountry: new UntypedFormControl(destinationCountryList, [Validators.required]),
      destinationCountryFilter: new UntypedFormControl(''),
      pod: new UntypedFormControl(podList, [Validators.required]),
      podFilter: new UntypedFormControl(''),
      perDiemType: new UntypedFormControl(this.agreement?.DIM_RUL_PT_ID || 0, [Validators.required]),
      freetime: new UntypedFormControl(this.agreement?.DIM_VL_FRT_AMT || '', [Validators.required]),
      beginDay1: new UntypedFormControl(this.agreement?.PERDIEM_LIST[0]?.DIM_VL_RNG_BEG || '', [Validators.required]),
      endDay1: new UntypedFormControl(this.agreement?.PERDIEM_LIST[0]?.DIM_VL_RNG_END || '', [Validators.required]),
      valuePerDay1: new UntypedFormControl(this.agreement?.PERDIEM_LIST[0]?.DIM_VL_RNG_VAL || '', [Validators.required]),
      beginDay2: new UntypedFormControl(this.agreement?.PERDIEM_LIST[1]?.DIM_VL_RNG_BEG || '', [Validators.required]),
      endDay2: new UntypedFormControl(this.agreement?.PERDIEM_LIST[1]?.DIM_VL_RNG_END || '', [Validators.required]),
      valuePerDay2: new UntypedFormControl(this.agreement?.PERDIEM_LIST[1]?.DIM_VL_RNG_VAL || '', [Validators.required]),
      beginDay3: new UntypedFormControl(this.agreement?.PERDIEM_LIST[2]?.DIM_VL_RNG_BEG || '', [Validators.required]),
      endDay3: new UntypedFormControl(this.agreement?.PERDIEM_LIST[2]?.DIM_VL_RNG_END || '', [Validators.required]),
      valuePerDay3: new UntypedFormControl(this.agreement?.PERDIEM_LIST[2]?.DIM_VL_RNG_VAL || '', [Validators.required]),
      beginDay4: new UntypedFormControl(this.agreement?.PERDIEM_LIST[3]?.DIM_VL_RNG_BEG || '', [Validators.required]),
      endDay4: new UntypedFormControl(this.agreement?.PERDIEM_LIST[3]?.DIM_VL_RNG_END || '', [Validators.required]),
      valuePerDay4: new UntypedFormControl(this.agreement?.PERDIEM_LIST[3]?.DIM_VL_RNG_VAL || '', [Validators.required])
    });

    this.agreementForm.get('groupFilter').valueChanges.subscribe(() => {
      this.filterGroups();
    });

    this.agreementForm.get('customerFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterCustomers(value);
    });

    this.agreementForm.get('shipperFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterShipper(value);
    });

    this.agreementForm.get('notifyFilter').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((value) => {
      this.filterNotify(value);
    });

    this.agreementForm.get('carrierFilter').valueChanges.subscribe(() => {
      this.filterCarriers();
    });

    this.agreementForm.get('containerTypeFilter').valueChanges.subscribe(() => {
      this.filterContainerTypes();
    });

    this.agreementForm.get('originTradeFilter').valueChanges.subscribe(() => {
      this.filterRegions();
    });

    this.agreementForm.get('originCountryFilter').valueChanges.subscribe(() => {
      this.filterCountries('origin');
    });

    this.agreementForm.get('polFilter').valueChanges.subscribe(() => {
      this.filterPorts('origin');
    });

    this.agreementForm.get('destinationTradeFilter').valueChanges.subscribe(() => {
      this.filterRegions();
    });

    this.agreementForm.get('destinationCountryFilter').valueChanges.subscribe(() => {
      this.filterCountries('destination');
    });

    this.agreementForm.get('podFilter').valueChanges.subscribe(() => {
      this.filterPorts('destination');
    });

    this.filteredRegions.subscribe(() => {
      if (this.agreementForm.get('originTrade').value.length !== 0) {
        this.setValidators('originTrade', ['originCountry', 'pol']);
      }
      if (this.agreementForm.get('destinationTrade').value.length !== 0) {
        this.setValidators('destinationTrade', ['destinationCountry', 'pod']);
      }
    });

    this.filteredOriginCountries.subscribe(() => {
      if (this.agreementForm.get('originCountry').value.length !== 0) {
        this.setValidators('originCountry', ['originTrade', 'pol']);
      }
    });

    this.filteredDestinationCountries.subscribe(() => {
      if (this.agreementForm.get('destinationCountry').value.length !== 0) {
        this.setValidators('destinationCountry', ['destinationTrade', 'pod']);
      }
    });

    this.filteredOriginPorts.subscribe(() => {
      if (this.agreementForm.get('pol').value.length !== 0) {
        this.setValidators('pol', ['originTrade', 'originCountry']);
      }
    });

    this.filteredDestinationPorts.subscribe(() => {
      if (this.agreementForm.get('pod').value.length !== 0) {
        this.setValidators('pod', ['destinationTrade', 'destinationCountry']);
      }
    });

    this.getBeginDayValue('freetime', 'beginDay1', 'valuePerDay1');
    this.getBeginDayValue('endDay1', 'beginDay2', 'valuePerDay2');
    this.getBeginDayValue('endDay2', 'beginDay3', 'valuePerDay3');
    this.getBeginDayValue('endDay3', 'beginDay4', 'valuePerDay4');

    this.validateFields();
    this.validateLocation();
    this.getContainerByType();

    if (this.agreementAction !== 'clone') {
      this.getDefaultRules();
    }
  }

  convertString(value: number): string{
    return String(value);
  }

  getCustomers(id: number, type: string): void{
    if(type === 'customer'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1, CUS_COUNTRY: 'BR'}).subscribe((customers)=>{
        this.customers = customers;
      });
    }

    if(type === 'shipper'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.shippers = customers;
      });
    }

    if(type === 'notify'){
      this.customerService.getCustomer({ CUS_ID: id ? id : null, IS_SELECT: 1 }).subscribe((customers)=>{
        this.shippers = customers;
        this.notifies = customers;
      });
    }
  }

  getDefaultRules(): void {
    this.defaultRules = [];

    const perdiemType: string = this.agreementForm.get('perDiemType')?.value;

    const agreement: IAgreement = {
      DIM_TYPE: this.agreementType,
      DIM_CAR: this.agreementForm.get('carrier')?.value || null,
      CTN_TYPE_PERDIEM: this.agreementForm.get('containerType').value ? this.agreementForm.get('containerType').value.join(',') : '',
      DIM_DT_STR_EFF: this.agreementForm.get('startDate')?.value || null,
      DIM_DT_FIN_EFF: this.agreementForm.get('validityDate')?.value || null,
      DIM_SERVICE: this.agreementForm.get('service')?.value,
      DIM_RUL_PT_ID: this.agreement?.DIM_RUL_PT_ID
    }

    this.agreementService.getDefaultRules(agreement).subscribe((defaultRules) => {
      this.defaultRules = defaultRules;

      if (perdiemType && perdiemType !== '0') {
        let defaultRule: IAgreement;
        defaultRules.map(item =>{ if(item.DIM_SK_CUS_RUL == Number(perdiemType)) defaultRule = item });

        if (defaultRule) {
          this.agreementService.getPerdiem(defaultRule).subscribe((perdiem: Array<IPerdiem>) => {
            this.defaultRulePerdiem = perdiem;
          });
        } else {
          this.defaultRulePerdiem = [];
          this.agreementForm.get('freetime').setValue('');
          this.agreement.DIM_RUL_PT_ID = 0;
          this.agreementForm.get('perDiemType').setValue(String(this.agreement?.DIM_RUL_PT_ID));
          this.clearPerDiem('freetime',0);
        }
      }
    });

    this.agreementForm.get('perDiemType').setValue(this.agreement?.DIM_RUL_PT_ID);
  }

  getPerdiem(agreement: IAgreement): void {
    this.agreementService.getPerdiem(agreement).subscribe((perdiem: Array<IPerdiem>) => {
      this.defaultRulePerdiem = perdiem;
      const freetime: number = this.agreementForm.get('freetime').value;

      const newPerdiem: Array<IPerdiem> = perdiem.filter(item => Number(item.DIM_VL_RNG_END) > freetime || !item.DIM_VL_RNG_END);

      this.agreementForm.patchValue({
        endDay1: newPerdiem[0]?.DIM_VL_RNG_END ? String(newPerdiem[0]?.DIM_VL_RNG_END) : "",
        valuePerDay1: newPerdiem[0]?.DIM_VL_RNG_VAL ? String(newPerdiem[0]?.DIM_VL_RNG_VAL) : "",
        endDay2: newPerdiem[1]?.DIM_VL_RNG_END ? String(newPerdiem[1]?.DIM_VL_RNG_END) : "",
        valuePerDay2: newPerdiem[1]?.DIM_VL_RNG_VAL ? String(newPerdiem[1]?.DIM_VL_RNG_VAL) : "",
        endDay3: newPerdiem[2]?.DIM_VL_RNG_END ? String(newPerdiem[2]?.DIM_VL_RNG_END) : "",
        valuePerDay3: newPerdiem[2]?.DIM_VL_RNG_VAL ? String(newPerdiem[2]?.DIM_VL_RNG_VAL) : "",
        endDay4: newPerdiem[3]?.DIM_VL_RNG_END ? String(newPerdiem[3]?.DIM_VL_RNG_END) : "",
        valuePerDay4: newPerdiem[3]?.DIM_VL_RNG_VAL ? String(newPerdiem[3]?.DIM_VL_RNG_VAL) : ""
      });

      this.getBeginDayValue('endDay1', 'beginDay2', 'valuePerDay2');
      this.getBeginDayValue('endDay2', 'beginDay3', 'valuePerDay3');
      this.getBeginDayValue('endDay3', 'beginDay4', 'valuePerDay4');

      for (let i = 1; i <= 4; i++) {
        if(!this.agreementForm.get(`valuePerDay${i}`).value){
          this.agreementForm.get(`valuePerDay${i}`).setValidators([]);
          this.agreementForm.get(`valuePerDay${i}`).updateValueAndValidity();
        }
      }
    });
  }

  verifyPerdiem(): void {
    if(this.agreementForm.get('perDiemType').value === '0') return;

    const freetime: number = this.agreementForm.get('freetime').value;

    const newPerdiem: Array<IPerdiem> = this.defaultRulePerdiem.filter(item => Number(item.DIM_VL_RNG_END) > freetime || !item.DIM_VL_RNG_END);

    this.agreementForm.patchValue({
      endDay1: newPerdiem[0]?.DIM_VL_RNG_END ? String(newPerdiem[0]?.DIM_VL_RNG_END) : "",
      valuePerDay1: newPerdiem[0]?.DIM_VL_RNG_VAL ? String(newPerdiem[0]?.DIM_VL_RNG_VAL) : "",
      endDay2: newPerdiem[1]?.DIM_VL_RNG_END ? String(newPerdiem[1]?.DIM_VL_RNG_END) : "",
      valuePerDay2: newPerdiem[1]?.DIM_VL_RNG_VAL ? String(newPerdiem[1]?.DIM_VL_RNG_VAL) : "",
      endDay3: newPerdiem[2]?.DIM_VL_RNG_END ? String(newPerdiem[2]?.DIM_VL_RNG_END) : "",
      valuePerDay3: newPerdiem[2]?.DIM_VL_RNG_VAL ? String(newPerdiem[2]?.DIM_VL_RNG_VAL) : "",
      endDay4: newPerdiem[3]?.DIM_VL_RNG_END ? String(newPerdiem[3]?.DIM_VL_RNG_END) : "",
      valuePerDay4: newPerdiem[3]?.DIM_VL_RNG_VAL ? String(newPerdiem[3]?.DIM_VL_RNG_VAL) : ""
    });

    this.getBeginDayValue('endDay1', 'beginDay2', 'valuePerDay2');
    this.getBeginDayValue('endDay2', 'beginDay3', 'valuePerDay3');
    this.getBeginDayValue('endDay3', 'beginDay4', 'valuePerDay4');

    for (let i = 1; i <= 4; i++) {
      if(!this.agreementForm.get(`valuePerDay${i}`).value){
        this.agreementForm.get(`valuePerDay${i}`).setValidators([]);
        this.agreementForm.get(`valuePerDay${i}`).updateValueAndValidity();
      }
    }
  }

  handleEndDayInput(endDay: string, nextBeginDay: string, nextValuePerDay: string, index: number): void {
    this.getBeginDayValue(endDay, nextBeginDay, nextValuePerDay);
    this.clearPerDiem(endDay, index);
  }

  getBeginDayValue(endDay: string, nextBeginDay: string, nextValuePerDay: string): void {
    this.agreementForm.get(nextBeginDay).setValue(Number(this.agreementForm.get(endDay).value) + 1);
    if (this.agreementForm.get(nextBeginDay).value !== 0) {
      this.agreementForm.get(nextValuePerDay).setValidators([Validators.required, Validators.pattern(/\-?\d*\.?\d{1,2}/)]);
      this.agreementForm.get(nextValuePerDay).updateValueAndValidity();
    }
  }

  clearPerDiem(endDay, index: number) {
    if (this.agreementForm.get(endDay).value) return;

    for (let i = index + 1; i <= 4; i++) {
      this.agreementForm.get(`endDay${i}`).setValue('');
      this.agreementForm.get(`valuePerDay${i}`).setValue('');
      this.agreementForm.get(`valuePerDay${i}`).setValidators([]);
      this.agreementForm.get(`valuePerDay${i}`).updateValueAndValidity();
    }
  }

  checkValidDate(endDay: string, lastBeginDay: string): void {
    if (!this.agreementForm.get(endDay).value) return;

    if (Number(this.agreementForm.get(endDay).value) < Number(this.agreementForm.get(lastBeginDay).value)) {
      this.agreementForm.get(endDay).setValue('');
      this.toast.error('The end day must be equal or bigger than the begin day of the same period');
    }
  }

  checkValidValuePerDay(valuePerDay: string, lastValuePerDay: string): void {
    if (!this.agreementForm.get(valuePerDay).value) return;

    if (Number(this.agreementForm.get(valuePerDay).value) <= Number(this.agreementForm.get(lastValuePerDay).value)) {
      this.agreementForm.get(valuePerDay).setValue('');
      this.toast.error('The value per day must be bigger than the one in the last period');
    }
  }

  checkStartDate() {
    const selectedDate = new Date(this.agreementForm.get('startDate').value);
    const currentDate = new Date();
    if ((selectedDate.getDate() < currentDate.getDate()) || (selectedDate.getMonth() < currentDate.getMonth()) || (selectedDate.getFullYear() < currentDate.getFullYear())) {
      this.agreementForm.get('startDate').setValue('');
      this.toast.error('The start date must not be earlier than today');
    }
  }

  checkValidityDate() {
    const selectedDate = new Date(this.agreementForm.get('validityDate').value);
    const startDate = new Date(this.agreementForm.get('startDate').value);
    if (selectedDate <= startDate) {
      this.agreementForm.get('validityDate').setValue('');
      this.toast.error('The validity date must be later the start date');
    }
  }

  filterGroups(): void {
    if (!this.customerGroups?.length) return;
    const filterValue = this.agreementForm.get('groupFilter').value.toLowerCase();
    this.filteredCustomerGroups.next(this.customerGroups.filter((group) => group.CUS_GRP_NAME.toLowerCase().includes(filterValue)));
  }

  filterCustomers(filter: string): void {
    if (!this.agreementForm.get('customer').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1, CUS_COUNTRY: 'BR'}).subscribe((customers)=>{
      this.customers = customers;
    })
  }

  filterShipper(filter: string): void {
    if (!this.agreementForm.get('shipper').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.shippers = customers;
    })
  }

  filterNotify(filter: string): void {
    if (!this.agreementForm.get('notify').value)
    this.customerService.getCustomer({ CUS_FILTER: filter, IS_SELECT: 1 }).subscribe((customers)=>{
      this.notifies = customers;
    })
  }

  filterCarriers(): void {
    if (!this.carriers?.length) return;
    const filterValue = this.agreementForm.get('carrierFilter').value.toLowerCase();
    this.filteredCarriers.next(this.carriers.filter((carrier) => carrier.RELATIPF_APCPTK.toLowerCase().includes(filterValue) || carrier.RELATIPF_APA3CD.toLowerCase().includes(filterValue)));
  }

  filterContainerTypes(): void {
    if (!this.containerTypes?.length) return;
    const filterValue = this.agreementForm.get('containerTypeFilter').value.toLowerCase();
    this.filteredContainerTypes.next(this.containerTypes.filter((containerType) => containerType.CONTYPPF_QAMPZ.toLowerCase().includes(filterValue)));
  }

  filterRegions(): void {
    if (!this.regions?.length) return;
    const filterValue = this.agreementForm.get('originTradeFilter').value.toLowerCase() || this.agreementForm.get('destinationTradeFilter').value.toLowerCase();
    this.filteredRegions.next(this.regions.filter((region) => region.REG_NAME.toLowerCase().includes(filterValue)));
  }

  filterCountries(type: string): void {
    if (!this.countries?.length) return;
    const filterValue = this.agreementForm.get('originCountryFilter').value.toLowerCase() || this.agreementForm.get('destinationCountryFilter').value.toLowerCase();

    if(type === 'origin'){
      if(this.agreementForm.get('service').value === 'demurrage'){
        this.filteredOriginCountries.next(this.countries.filter((country) =>
          country.COU_CODE.toLowerCase().includes(filterValue) && country.COU_CODE !== 'BR'
        ));
      }else{
        this.filteredOriginCountries.next(this.countries.filter((country) =>
          country.COU_CODE.toLowerCase().includes(filterValue) && country.COU_CODE === 'BR'
        ));
      }
    }else{
      if(this.agreementForm.get('service').value === 'demurrage'){
        this.filteredDestinationCountries.next(this.countries.filter((country) =>
          country.COU_CODE.toLowerCase().includes(filterValue) && country.COU_CODE === 'BR'
        ));
      }else{
        this.filteredDestinationCountries.next(this.countries.filter((country) =>
          country.COU_CODE.toLowerCase().includes(filterValue) && country.COU_CODE !== 'BR'
        ));
      }
    }
  }

  filterPorts(type: string): void {
    if (!this.ports?.length) return;
    const filterValue = this.agreementForm.get('polFilter').value.toLowerCase() || this.agreementForm.get('podFilter').value.toLowerCase();

    if(type === 'origin'){
      if(this.agreementForm.get('service').value === 'demurrage'){
        this.filteredOriginPorts.next(this.ports.filter((port) =>
          port.DHLPORTS_AIAOCD.toLowerCase().includes(filterValue) && port.DHLPORTS_AIAACD !== 'BR'
        ));
      }else{
        this.filteredOriginPorts.next(this.ports.filter((port) =>
          port.DHLPORTS_AIAOCD.toLowerCase().includes(filterValue) && port.DHLPORTS_AIAACD === 'BR'
        ));
      }
    }else{
      if(this.agreementForm.get('service').value === 'demurrage'){
        this.filteredDestinationPorts.next(this.ports.filter((port) =>
          port.DHLPORTS_AIAOCD.toLowerCase().includes(filterValue) && port.DHLPORTS_AIAACD === 'BR'
        ));
      }else{
        this.filteredDestinationPorts.next(this.ports.filter((port) =>
          port.DHLPORTS_AIAOCD.toLowerCase().includes(filterValue) && port.DHLPORTS_AIAACD !== 'BR'
        ));
      }
    }
  }

  filterService(): void{
    if(this.agreementForm.get('service').value === 'demurrage'){
      this.filteredOriginCountries.next(this.countries?.filter((country) => country.COU_CODE !== 'BR'))
      this.filteredOriginPorts.next(this.ports?.filter((port) => port.DHLPORTS_AIAACD !== 'BR'));
      this.filteredDestinationCountries.next(this.countries?.filter((country) => country.COU_CODE === 'BR'))
      this.filteredDestinationPorts.next(this.ports?.filter((port) => port.DHLPORTS_AIAACD === 'BR'));
    }else{
      this.filteredOriginCountries.next(this.countries?.filter((country) => country.COU_CODE === 'BR'))
      this.filteredOriginPorts.next(this.ports?.filter((port) => port.DHLPORTS_AIAACD === 'BR'));
      this.filteredDestinationCountries.next(this.countries?.filter((country) => country.COU_CODE !== 'BR'))
      this.filteredDestinationPorts.next(this.ports?.filter((port) => port.DHLPORTS_AIAACD !== 'BR'));
    }
  }

  onFormSubmit(e: Event): void {
    e.preventDefault();
    const formValue = this.agreementForm.value;

    let containerTypeList: Array<IRulCtnType> = [];
    let regionOrigin: Array<IRulTrd> = [];
    let regionDestination: Array<IRulTrd> = [];
    let couListOrigin: Array<IRulCou> = [];
    let couListDestination: Array<IRulCou> = [];
    let portListOrigin: Array<IRulPort> = [];
    let portListDestination: Array<IRulPort> = [];

    if (formValue.containerType?.length !== 0) {
      containerTypeList = formValue.containerType?.map((containerType: number) => {
        const cntType: IRulCtnType = {
          DIM_SK_CTN_TYP: containerType
        }
        return cntType;
      });
    }

    if (formValue.originTrade?.length !== 0) {
      regionOrigin = formValue.originTrade?.map((region: number) => {
        const trd: IRulTrd = {
          REG_ID: region,
          DIM_RUL_TRD_TYPE: 'O'
        }
        return trd;
      });
    }

    if (formValue.destinationTrade?.length !== 0) {
      regionDestination = formValue.destinationTrade?.map((region: number) => {
        const trd: IRulTrd = {
          REG_ID: region,
          DIM_RUL_TRD_TYPE: 'D'
        }
        return trd;
      });
    }

    if (formValue.originCountry?.length !== 0) {
      couListOrigin = formValue.originCountry?.map((country: number) => {
        const cou: IRulCou = {
          COU_ID: country,
          DIM_RUL_COU_TYPE: 'O'
        }
        return cou;
      });
    }

    if (formValue.destinationCountry?.length !== 0) {
      couListDestination = formValue.destinationCountry?.map((country: number) => {
        const cou: IRulCou = {
          COU_ID: country,
          DIM_RUL_COU_TYPE: 'D'
        }
        return cou;
      });
    }

    if (formValue.pol?.length !== 0) {
      portListOrigin = formValue.pol?.map((port: number) => {
        const pol: IRulPort = {
          DIM_SK_PORT: port,
          DIM_RUL_PORT_TYPE: 'O'
        }
        return pol;
      });
    }

    if (formValue.pod?.length !== 0) {
      portListDestination = formValue.pod?.map((port: number) => {
        const pol: IRulPort = {
          DIM_SK_PORT: port,
          DIM_RUL_PORT_TYPE: 'D'
        }
        return pol;
      });
    }

    const regList = regionOrigin.concat(regionDestination);
    const couList = couListOrigin.concat(couListDestination);
    const portList = portListOrigin.concat(portListDestination);

    const agreement: IAgreement = {
      DIM_DOSIERPF_BHFFTX: formValue.hblmbl,
      DIM_DESCRIPTION: formValue.description,
      DIM_TYPE: this.agreementType,
      DIM_SERVICE: formValue.service,
      DIM_DT_STR_EFF: formValue.startDate,
      DIM_DT_FIN_EFF: formValue.validityDate,
      DIM_DT_BID_EXP: formValue.bidDate,
      DIM_STATUS: formValue.status,
      DIM_DGF: formValue.dhlContract,
      DIM_REQUESTED: this.requestedByList.join(', '),
      DIM_APPROVED: this.approvedByList.join(', '),
      DIM_CUS_CODE: formValue.customer || null,
      DIM_CUS_GRP: formValue.group || null,
      DIM_SHIPPER: formValue.shipper || null,
      DIM_NOTIFY: formValue.notify || null,
      DIM_CAR: formValue.carrier != 0 ? formValue.carrier : null,
      DIM_CTN_TYPE: formValue.containerType ? formValue.containerType.join(',') : null,
      DIM_NAC_FAK: formValue.faknac,
      DIM_CONTRACT: formValue.contract,
      DIM_VL_FRT_AMT: formValue.freetime,
      DIM_STR_REF: formValue.startReference,
      DIM_END_REF: formValue.endReference,
      CTN_TYPE_LIST: containerTypeList,
      REG_LIST: regList,
      COU_LIST: couList,
      PORT_LIST: portList,
      PERDIEM_LIST: [
        {
          DIM_VL_RNG_BEG: formValue.beginDay1 || null,
          DIM_VL_RNG_END: formValue.endDay1 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay1.replace(",",".") || null,
          DIM_CD_RNG_ORG: '1'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay2 || null,
          DIM_VL_RNG_END: formValue.endDay2 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay2.replace(",",".") || null,
          DIM_CD_RNG_ORG: '2'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay3 || null,
          DIM_VL_RNG_END: formValue.endDay3 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay3.replace(",",".") || null,
          DIM_CD_RNG_ORG: '3'
        },
        {
          DIM_VL_RNG_BEG: formValue.beginDay4 || null,
          DIM_VL_RNG_END: formValue.endDay4 || null,
          DIM_VL_RNG_VAL: formValue.valuePerDay4.replace(",",".") || null,
          DIM_CD_RNG_ORG: '4'
        }
      ],
      DIM_RUL_DEF: 0,
      DIM_RUL_PT_ID: formValue.perDiemType,
      DIM_PERDIEM_TYPE: null,
      DIM_RUL_USER: this.authService.userId
    }

    if (!this.agreement || this.agreementAction === 'clone') {
      agreement.DIM_STATUS = 1;
      this.agreementService.postAgreement(agreement).subscribe((response) => {
        this.toast.success(String(response));
        const dialogRef = this.dialog.open(AgreementDialogComponent);
          dialogRef.afterClosed().subscribe((willClone) => {
            if (willClone === 0) {
              this.router.navigate(['/agreements', this.agreementType]);
            } else if (willClone === 1) {
              window.location.reload();
              window.scrollTo(0, 0);
            } else {
              this.agreementForm.patchValue({
                carrier: '',
                faknac: '',
                contract: '',
                containerType: [],
                perDiemType: '0',
                freetime: '',
                beginDay1: '',
                endDay1: '',
                valuePerDay1: '',
                beginDay2: '',
                endDay2: '',
                valuePerDay2: '',
                beginDay3: '',
                endDay3: '',
                valuePerDay3: '',
                beginDay4: '',
                endDay4: '',
                valuePerDay4: ''
              });
              window.scrollTo(0, 0);
              this.cloned = true;
              this.isAgreementInformationOpen = false;
              this.isCustomerInformationOpen = false;
            }
          });
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    } else {
      agreement.DIM_SK_CUS_RUL = this.agreementId;
      this.agreementService.putAgreement(agreement).subscribe((response) => {
        this.toast.success(String(response));
        this.getTimeline();
      }, (error) => {
        this.toast.error(error.error.Message);
      });
    }
  }

  isFieldDisabled(field1: string, field2: string): boolean {
    return this.agreementForm.get(field1).value.length !== 0 || this.agreementForm.get(field2).value.length !== 0;
  }

  addRequested(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    this.agreementForm.get('requestedBy').setValue(value);

    if (value && this.agreementForm.get('requestedBy').hasError('email')) {
      this.toast.error('You must provide a valid email.');
    } else if (value) {
      this.requestedByList.push(value);
    }

    event.chipInput!.clear();
  }

  removeRequested(requested: string): void {
    const index = this.requestedByList.indexOf(requested);

    if (index >= 0) {
      this.requestedByList.splice(index, 1);
    }

    if (this.requestedByList.length === 0) {
      this.agreementForm.get('requestedBy').setValue('');
    }
  }

  addApproved(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    this.agreementForm.get('approvedBy').setValue(value);

    if (value && this.agreementForm.get('approvedBy').hasError('email')) {
      this.toast.error('You must provid a valid email.');
    } else if (value) {
      this.approvedByList.push(value);
    }

    event.chipInput!.clear();
  }

  removeApproved(approved: string): void {
    const index = this.approvedByList.indexOf(approved);

    if (index >= 0) {
      this.approvedByList.splice(index, 1);
    }

    if (this.approvedByList.length === 0) {
      this.agreementForm.get('approvedBy').setValue('');
    }
  }

  validateService(){
    this.agreementForm.get('originTrade').setValue([]);
    this.agreementForm.get('destinationTrade').setValue([]);
    this.agreementForm.get('originCountry').setValue([]);
    this.agreementForm.get('destinationCountry').setValue([]);
    this.agreementForm.get('pol').setValue([]);
    this.agreementForm.get('pod').setValue([]);
    this.agreementForm.get('freetime').setValue('');
    this.agreementForm.get('perDiemType').setValue(0);
    this.clearPerDiem('freetime', 0);

    this.validateLocation();
  }

  validateLocation(){
    this.filterService();

    if(this.agreementForm.get('service').value === 'demurrage'){
      this.agreementForm.get('startReference').setValue('Arrival');
      this.agreementForm.get('endReference').setValue('Empty Return');
    }else if(this.agreementForm.get('service').value === 'detention'){
      if(this.agreementForm.get('description').value === 'bulk-bid' || this.agreementForm.get('description').value === 'bulk-spot'){
        this.agreementForm.get('startReference').setValue('Gate Out');
        this.agreementForm.get('endReference').setValue('Loading');
      }
      else {
        this.agreementForm.get('startReference').setValue('Gate Out');
        this.agreementForm.get('endReference').setValue('Gate In');
      }

    }

    if(this.agreementForm.get('service').value === 'demurrage' && !this.agreementForm.get('hblmbl').value){
      this.agreementForm.get('originTrade').setValidators([]);
      this.agreementForm.get('originTrade').updateValueAndValidity();

      this.agreementForm.get('originCountry').setValidators([]);
      this.agreementForm.get('originCountry').updateValueAndValidity();

      this.agreementForm.get('pol').setValidators([]);
      this.agreementForm.get('pol').updateValueAndValidity();

      this.agreementForm.get('destinationTrade').setValidators([Validators.required]);
      this.agreementForm.get('destinationTrade').updateValueAndValidity();

      this.agreementForm.get('destinationCountry').setValidators([Validators.required]);
      this.agreementForm.get('destinationCountry').updateValueAndValidity();

      this.agreementForm.get('pod').setValidators([Validators.required]);
      this.agreementForm.get('pod').updateValueAndValidity();

      this.getCustomers(null, 'shipper');
      this.getCustomers(null, 'notify');
    }else if(this.agreementForm.get('service').value === 'detention' && !this.agreementForm.get('hblmbl').value){
      this.agreementForm.get('shipper').setValue(null);
      this.agreementForm.get('notify').setValue(null);

      this.agreementForm.get('destinationTrade').setValidators([]);
      this.agreementForm.get('destinationTrade').updateValueAndValidity();

      this.agreementForm.get('destinationCountry').setValidators([]);
      this.agreementForm.get('destinationCountry').updateValueAndValidity();

      this.agreementForm.get('pod').setValidators([]);
      this.agreementForm.get('pod').updateValueAndValidity();

      this.agreementForm.get('originTrade').setValidators([Validators.required]);
      this.agreementForm.get('originTrade').updateValueAndValidity();

      this.agreementForm.get('originCountry').setValidators([Validators.required]);
      this.agreementForm.get('originCountry').updateValueAndValidity();

      this.agreementForm.get('pol').setValidators([Validators.required]);
      this.agreementForm.get('pol').updateValueAndValidity();
    }else if(this.agreementForm.get('hblmbl').value){
      this.agreementForm.get('originTrade').setValidators([]);
      this.agreementForm.get('originTrade').updateValueAndValidity();

      this.agreementForm.get('originCountry').setValidators([]);
      this.agreementForm.get('originCountry').updateValueAndValidity();

      this.agreementForm.get('pol').setValidators([]);
      this.agreementForm.get('pol').updateValueAndValidity();

      this.agreementForm.get('destinationTrade').setValidators([]);
      this.agreementForm.get('destinationTrade').updateValueAndValidity();

      this.agreementForm.get('destinationCountry').setValidators([]);
      this.agreementForm.get('destinationCountry').updateValueAndValidity();

      this.agreementForm.get('pod').setValidators([]);
      this.agreementForm.get('pod').updateValueAndValidity();
    }
  }

  validateFields(): void {
    const description = this.agreementForm.get('description').value;

    const skipBidValidation: Array<string>  = ['hblmbl', 'bidDate', 'dhlContract', 'shipper', 'notify', 'carrier','faknac', 'contract', 'groupFilter', 'customerFilter', 'shipperFilter', 'notifyFilter', 'carrierFilter', 'containerTypeFilter', 'originTradeFilter', 'originCountryFilter', 'polFilter', 'destinationTradeFilter', 'destinationCountryFilter', 'podFilter'];

    const skipSpotValidation: Array<string> = ['startDate', 'validityDate', 'bidDate', 'dhlContract', 'approvedBy', 'group', 'customer', 'shipper', 'notify', 'carrier', 'faknac', 'contract', 'originTrade', 'originCountry', 'pol', 'destinationTrade', 'destinationCountry', 'pod', 'groupFilter', 'customerFilter' ,'shipperFilter', 'notifyFilter', 'carrierFilter', 'containerTypeFilter', 'originTradeFilter', 'originCountryFilter', 'polFilter', 'destinationTradeFilter', 'destinationCountryFilter', 'podFilter'];

    const skipFakValidation: Array<string>  = ['hblmbl', 'bidDate', 'dhlContract', 'group', 'customer','groupFilter', 'customerFilter','shipper', 'notify', 'carrier','faknac', 'contract', 'groupFilter', 'customerFilter', 'shipperFilter', 'notifyFilter', 'carrierFilter', 'containerTypeFilter', 'originTradeFilter', 'originCountryFilter', 'polFilter', 'destinationTradeFilter', 'destinationCountryFilter', 'podFilter'];

    Object.keys(this.agreementForm.controls).forEach(field => {
      let customValidators = [];
      switch (field) {
        case 'requestedBy':
          if (this.requestedByList.length !== 0) {
            customValidators = [Validators.email];
          } else {
            customValidators = [Validators.required, Validators.email];
          }
          break;
        case 'approvedBy':
          if (this.approvedByList.length !== 0) {
            customValidators = [Validators.email];
          } else {
            customValidators = [Validators.required, Validators.email];
          }
          break;
        case 'freetime':
          customValidators = [Validators.required, Validators.pattern('[0-9]*')];
          break;
        case 'beginDay1':
        case 'endDay1':
        case 'beginDay2':
        case 'endDay2':
        case 'beginDay3':
        case 'endDay3':
        case 'beginDay4':
        case 'endDay4':
          customValidators = [Validators.pattern('[0-9]*')];
          break;
        case 'valuePerDay1':
        case 'valuePerDay2':
        case 'valuePerDay3':
        case 'valuePerDay4':
          customValidators = [Validators.pattern(/\-?\d*\.?\d{1,2}/)];
          break;
        default:
          customValidators = [Validators.required];
          break;
      }

      const control = this.agreementForm.get(field);
      if (description === 'bid' || description === 'fak' || description === 'bulk-bid') {
        this.agreementForm.get('hblmbl').setValue('');

        if(description === 'bid' || description === 'bulk-bid'){
          if (!skipBidValidation.includes(field)) {
            control.setValidators(customValidators);
            control.updateValueAndValidity();
          } else {
            control.setValidators([]);
            control.updateValueAndValidity();
          }

          if (this.agreementForm.get('customer').value) {
            this.setValidators('customer',['group']);
          } else if (this.agreementForm.get('group').value) {
            this.setValidators('group', ['customer']);
          }
        }else{
          if (!skipFakValidation.includes(field)) {
            control.setValidators(customValidators);
            control.updateValueAndValidity();
          } else {
            control.setValidators([]);
            control.updateValueAndValidity();
          }
        }

        if (this.agreementForm.get('originTrade').value.length !== 0) {
          this.setValidators('originTrade', ['originCountry', 'pol']);
        }
        if (this.agreementForm.get('destinationTrade').value.length !== 0) {
          this.setValidators('destinationTrade', ['destinationCountry', 'pod']);
        }
        if (this.agreementForm.get('originCountry').value.length !== 0) {
          this.setValidators('originCountry', ['originTrade', 'pol']);
        }
        if (this.agreementForm.get('destinationCountry').value.length !== 0) {
          this.setValidators('destinationCountry', ['destinationTrade', 'pod']);
        }
        if (this.agreementForm.get('pol').value.length !== 0) {
          this.setValidators('pol', ['originTrade', 'originCountry']);
        }
        if (this.agreementForm.get('pod').value.length !== 0) {
          this.setValidators('pod', ['destinationTrade', 'destinationCountry']);
        }

      } else if (description === 'spot' || description === 'bulk-spot') {
        this.agreementForm.get('startDate').setValue('');
        this.agreementForm.get('validityDate').setValue('');
        this.agreementForm.get('bidDate').setValue('');

        if (!skipSpotValidation.includes(field)) {
          control.setValidators(customValidators);
          control.updateValueAndValidity();
        } else {
          control.setValidators([]);
          control.updateValueAndValidity();
        }
      }
    });

    for (let i = 1; i <= 4; i++) {
      if(!this.agreementForm.get(`valuePerDay${i}`).value){
        this.agreementForm.get(`beginDay${i}`).setValidators([]);
        this.agreementForm.get(`beginDay${i}`).updateValueAndValidity();

        this.agreementForm.get(`endDay${i}`).setValidators([]);
        this.agreementForm.get(`endDay${i}`).updateValueAndValidity();

        this.agreementForm.get(`valuePerDay${i}`).setValidators([]);
        this.agreementForm.get(`valuePerDay${i}`).updateValueAndValidity();
      }
    }
  }

  getErrorMessage(formControl: string): string {
    if (this.agreementForm.get(formControl).hasError('email')) {
      return 'You must enter a valid email';
    } else if (this.agreementForm.get(formControl).hasError('pattern')) {
      return 'The value must be a number';
    } else if (this.agreementForm.get(formControl).hasError('required')) {
      return 'You must enter a value';
    } else {
      return '';
    }
  }

  setValidators(currentField: string, targetFields: Array<string>): void {
    const description = this.agreementForm.get('description').value;
    if (description === 'bid' || description === 'fak'  || description === 'bulk-bid') {
      const value = this.agreementForm.get(currentField).value;
      if (value && value.length !== 0) {
        targetFields.forEach((field) => {
          this.agreementForm.get(field).setValidators([]);
          this.agreementForm.get(field).updateValueAndValidity();
        });
      } else {
        targetFields.forEach((field) => {
          this.agreementForm.get(field).setValidators([Validators.required]);
          this.agreementForm.get(field).updateValueAndValidity();
        });
      }
    } else {
      this.agreementForm.get(currentField).setValidators([]);
      this.agreementForm.get(currentField).updateValueAndValidity();
      targetFields.forEach((field) => {
        this.agreementForm.get(field).setValidators([]);
        this.agreementForm.get(field).updateValueAndValidity();
      });
    }
  }

  openCustomerGroupDialog(data: ICustomerGroupDialog): void {
    let dialogRef = this.dialog.open(CustomerGroupsDialogComponent, { data });

    dialogRef.afterClosed().subscribe((group: ICustomerGroup) => {
      if(!group) return;

      this.customerGroupService.postCustomerGroup(group).subscribe((response) => {
        this.toast.success(String(response));
        this.customerGroupService.getCustomerGroup().subscribe((customerGroups) => {
          this.customerGroups = customerGroups;
          this.filteredCustomerGroups.next(customerGroups);
        });
      },(error) => {
        this.toast.error(error.error.Message);
      });
    });
  }

  openSidebar(): void {
    this.isSidebarOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeSidebar(): void {
    this.isSidebarOpen = false;
    document.body.style.overflow = 'unset';
  }

  getMblData(): void {
    const hbl = this.agreementForm.get('hblmbl').value.trim();
    this.agreementForm.get('hblmbl').setValue(hbl);

    const agreement: IAgreement = { DIM_DOSIERPF_BHFFTX: hbl }
    this.agreementService.getShipData(agreement).subscribe((shipmentData) => {
      if (!shipmentData.length) {
        this.agreementForm.patchValue({
          customer: '',
          pol: [],
          pod: [],
          service: '',
          carrier: '',
          startReference: '',
          endReference: '',
          containerType: []
        });

        return;
      }

      const data = shipmentData[0];

      this.agreementForm.patchValue({
        startDate: data.SHIP_ATD,
        validityDate: data.SHIP_ATD,
        customer: data.SHIP_CUS,
        pol: [data.SHIP_POL],
        pod: [data.SHIP_POD],
        service: data.SHIP_SERVICE,
        carrier: data.SHIP_CAR,
        containerType: data.SHIP_CTN_TYPE.split(', ').map(Number)
      });
      this.getCustomers(data.SHIP_CUS, 'customer');
      this.validateLocation();
      this.getDefaultRules();
    });

    this.agreementService.spotChecker({ DIM_DOSIERPF_BHFFTX: hbl }).subscribe((data)=>{
      if(Array.isArray(data) && data.length > 0){
        this.toast.warning('A agreement has already been established for this process.');
      }
    });
  }

  getComments(): void {
    this.commentService.getComments({ COMM_REF_GROUP: `agreement`, REG_ID: this.agreementId }).subscribe(comments => this.comments = comments)
  }

  onComment(comment: string): void {
    const newComment: IComment = {
      COMM_MESSAGE: comment,
      COMM_USER: this.authService.userId,
      COMM_REF: [
        {
          REG_ID: this.agreementId,
          COMM_REF_GROUP: `agreement`,
          COMM_REF_USER: this.authService.userId
        }
      ]
    };

    this.commentService.postComment(newComment).subscribe((response) => {
      this.toast.success(String(response));
      this.getComments();
      this.getTimeline();
    }, error => this.toast.error(error.error.Message));
  }

  getAttachments(): void {
    this.attachmentService.getAttachments({ ATCH_REF_GROUP: `agreement`, REG_ID: this.agreementId }).subscribe(attachments => this.attachments = attachments);
  }

  onUpload(files: Array<File>) {
    this.awsService
      .uploadFiles(files, `agreement`, [{ REG_ID: this.agreementId }])
      .pipe(this.toast.observe({
        loading: 'Uploading files...',
        success: () => 'Files uploaded successfully.',
        error: (e) => `Error uploading files: ${e}`
      }))
      .subscribe((attachments) => {
        this.attachmentService.postAttachment(attachments).subscribe((response) => {
          this.toast.success(String(response));
          this.getAttachments();
          this.getTimeline();
        }, (error) => {
          this.toast.error(error.error.Message)
        });
      });
  }

  getTimeline(): void {
    const timeline: ITimeline = {
      TIMELINE_GROUP: 'agreement',
      TIMELINE_REG_ID: this.agreementId?.toString()
    }

    this.timelineService.getTimeline(timeline).subscribe((timeline) => {
      this.timeline = timeline.map((event: ITimeline) => {
        return {...event, TIMELINE_DATETIME: new Date(event.TIMELINE_DATETIME)};
      });
    });
  }

  validateApprovedBy(){
    const description = this.agreementForm.get('description').value;

    if(description === 'spot' || description === 'bulk-spot'){
      this.approvedByList = [];
      this.agreementForm.get('approvedBy').setValue('');
      this.approvedByList.push('dgf.br.demurrage@dhl.com')
    }else{
      this.approvedByList = [];
      this.agreementForm.get('approvedBy').setValue('');
    }
  }

  getContainerByType(){
    const typeIsotank: IContainerType = {
      CONTYPPF_QAMPZ: 'TC',
      CONTYPPF_QAMQA: null,
      CONTYPPF_QAMQB: null,
      CON_TYPE_USER: null
    };

    if(this.agreementForm.get('description').value === 'bulk-bid' || this.agreementForm.get('description').value === 'bulk-spot'){
      this.parameterService.getIsotankContainerType(typeIsotank).subscribe((containerTypes) => {
        this.containerTypes = containerTypes;
        this.filteredContainerTypes.next(containerTypes);
      });
    }
    else{
      this.parameterService.getContainerTypeParameter().subscribe((containerTypes) => {
        this.containerTypes = containerTypes;
        this.filteredContainerTypes.next(containerTypes);
      });
    }
  }

  checkFormErrors() {
    Object.keys(this.agreementForm.controls).forEach(key => {
      const control = this.agreementForm.get(key);
      if (control && control.invalid) {
        console.log(`Campo com erro: ${key}`);
        console.log(control.errors);
      }
    });
  }
}
